
.sliderFullscreen .toggle{
	list-style-type:none;
	bottom:10%;
	left:10%;
	padding:0;
	justify-content: space-between;
}
.sliderFullscreen .toggle li {
	padding-bottom:5px;
	width:50px;
}
.sliderFullscreen .toggle li a {
	color:#fff;
	padding-bottom:5px;
}
.sliderFullscreen .toggle li a:hover {
	text-decoration: none;
}
.sliderFullscreen .uk-active{
	border-bottom:2px solid #fff;
}
.sliderFullscreen .slide{
	position:relative;
	width:100%;
	height:100vh;
	cursor:default;
}
.sliderFullscreen .slide .content{
	position:absolute;
	top:60%;
	left:20%;
	z-index:1;
	width:25%;
}
.sliderFullscreen  .slide .content h1{
	width:100%;
	text-align:left;
	color:#fff;
	line-height:150%;
}
.sliderFullscreen .slide  .content h2{
	width:100%;
	text-align:left;
	margin:0 0 20px 0;
	color:#fff;
	line-height:150%;
}
.sliderFullscreen .slide .content button{
	float:left;
	padding:10px;
	color:#000;
	background-color:#fff;
	width:50%;
	border-radius:25px;
	border:0;
	font-size:20px;
	font-weight:bold;
	cursor:pointer;
}
/* medium */
@media screen and (max-width:1047px){
.sliderFullscreen .slide{
	position:relative;
	width:100%;
	height:100vh;
}
.sliderFullscreen .slide img{
	height:100%;
	object-fit:cover;
}
.sliderFullscreen .slide .content{
	position:absolute;
	bottom:30%;
	left:30%;
	z-index:1;
	width:50%;
}


}

/* small */
@media screen and (max-width:639px){
.sliderFullscreen .slide{
	position:relative;
	width:100%;
	height:100vh;
}
.sliderFullscreen .slide img{
	height:100%;
	object-fit:cover;
}
.sliderFullscreen .toggle{
	list-style-type:none;
	bottom:20%;
	left:50%;
	transform:translateX(-50%);
	padding:0;
	margin-bottom:0;
}
.sliderFullscreen .slide .content{
	width:100%;
	left:50%;
	top:50%;
	text-align: center;
}
.sliderFullscreen .slide .content h1{
	width:100%;
	font-size:26px;
	text-align:center;
}
.sliderFullscreen .slide .content h2{
	text-align:center;
}
.sliderFullscreen .slide .content button{
	padding:10px;
	width:50%;
	font-size:16px;
	float:none;
}
}
