
.Register{
  width:30%;
  height:500px;
  margin:100px auto;
  background-color:#fff;
  text-align: left;
  box-shadow:2px 2px 10px rgba(0,0,0,0.2);
  position:relative;
}
.Register__account{
  width:70%;
  margin:0 auto;
  margin-top:50px;
  padding:20px;
}
.Register__pwd{
  width:70%;
  margin:10px auto;
  padding:10px;
  position:relative;
}
.Register__college{
  width:40%;
  margin:10px 0;
  margin-left:13%;
  padding:10px;
  position:relative;
  display:inline-block;
}
.Register__identity{
  width:20%;
  margin:10px auto;
  margin-left:10%;
  padding:10px;
  position:relative;
  display:inline-block;
  vertical-align:top;
}
.Register__input--identity{
  font-size:16px;
}
.Register__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.Register__input{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
  font-size:16px;
}
.Register__mask{
  background-image:url('../img/form__mask.png');
  width:20px;
  height:20px;
  border:0;
  background-size:contain;
  position:absolute;
  top:55%;
  left:80%;
}
.Register__mask:focus{
  outline:0;
}
.Register__unmask{
  background-image:url('../img/form__unmask.png');
  width:20px;
  height:20px;
  border:0;
  background-size:contain;
  position:absolute;
  top:55%;
  left:80%;
}
.Register__button{
  background-color:#000;
  color:#fff;
  border:none;
  padding:20px 0;
  font-size:16px;
  width:50%;
  position:absolute;
  top:340px;
  left:50%;
  transform:translateX(-50%);
  cursor: pointer;
}

.Return__button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 20px 0;
  font-size: 16px;
  width: 50%;
  position: absolute;
  top: 420px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.Register__button--press{
  background-color:rgba(0, 0, 0, 0.3);
  color:#fff;
  border:none;
  padding:20px 0;
  font-size:16px;
  width:50%;
  position:absolute;
  top:400px;
  left:50%;
  transform:translateX(-50%);
}
.Register__prompt{
  position:absolute;
  color:red;
}
.Register__recaptcha{
  position:absolute;
  top:350px;
  left:50%;
  transform:translateX(-50%);
}
@media screen and (max-width:768px){
  .Register{
    width:100%;
  }
}
